@media (max-width: 1367px) {
  #subheader {
    font-size: 45px;
    margin-top: 20px;
  }

  .select_produse_general,
  .sectiune_inputuri,
  .container_caramida {
    padding: 0px 50px;
  }

  .tl_general {
    font-size: 24px;
  }

  .input_dreapta {
    font-size: 17px;
  }
  .select_general {
    font-size: 17px;
    padding-bottom: 10px;
    margin-top: 25px;
  }

  div#lista-calculatoare-content {
    width: calc(100% - 60px);
    /*height: 550px;*/
  }

  .title_meniu {
    font-size: 34px;
  }

  .item-imagine img {
    /*max-width: 75%;*/
  }

  .item-main-title {
    font-size: 38px;
  }

  .item-title-grey {
    font-size: 34px;
  }

  .text_meniu {
    line-height: 40px;
  }

  .subtitle_meniu {
    font-size: 42px;
  }

  .item-title {
    font-size: 17px;
    display: block;
  }
  .refa_calculul {
    font-size: 26px;
    padding: 14px 0;
  }

  .adauga_oferta_in_cos {
    font-size: 26px;
    padding: 14px 0;
  }

  .tl_mare {
    font-size: 30px;
  }

  .black_item {
    font-size: 23px;
  }

  .red_item {
    font-size: 17px;
  }

  div#calculeaza-button {
    height: 80px;
    width: 280px;
  }

  .info-constructie-info {
    font-size: 14px;
  }

  .poza_stanga {
    width: 40%;
  }
  .info_right {
    width: 60%;
  }

  .poza_stanga img {
    width: 90%;
  }

  .flxx .info-constructie-item {
    width: 48%;
  }

  #header {
    padding: 10px 0;
  }
}

@media (max-width: 1200px) {

  .select_inner {
    flex-wrap: wrap;
  }
  .sl_general_placaj {
    width: 100%;
    margin-bottom: 2rem;
  }
  .select_produse_general {
    margin-top: 30px;
  }
}

@media (max-width: 1025px) {
  #componenta-cart {
    padding-left: 20px;
    padding-right: 20px;
  }

  .select_produse_general,
  .sectiune_inputuri,
  .container_caramida {
    padding: 0px 30px;
  }

  .imagine_necesar {
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
  }

  .necesar_right {
    width: 100%;
  }

  .necesar_flex {
    flex-wrap: wrap;
  }

  .box_cantitate {
    text-align: center;
  }

  .input_red {
    text-align: center;
  }

  .meniu_container {
    padding: 75px 100px;
  }
}

@media (max-width: 820px) {
  #subheader {
    font-size: 35px;
    line-height: 38px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .copy_subheader {
    padding-left: 25px;
    padding-right: 25px;
  }
  .select_produse_general, .sectiune_inputuri, .container_caramida {
    padding: 0px 20px;
  }
}

@media (max-width: 768px) {
  .subtitle_meniu {
    font-size: 30px;
  }

  .title_meniu {
    font-size: 26px;
  }
  .termeni_general {
    font-size: 26px;
  }
  .termeni {
    margin-left: 100px;
  }

  .poza_stanga {
    width: 90%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 50px;
  }
  .info_right {
    width: 100%;
  }
  .sectiune_inputuri {
    flex-wrap: wrap;
  }

  div#calculeaza-button {
    height: 80px;
    width: 280px;
    margin: 31px auto 100px auto;
  }

  .header2 {
    padding: 40px 20px 15px 20px;
  }

  .meniu_site {
    background-size: 54%;
    background-position: bottom -55px right -54px;
  }
}

@media (max-width: 700px) {
  .butoane_cart{
    flex-direction: column;
  }
  .adauga_oferta_in_cos {
    width: fit-content;
    padding-right: 20px !important;
    padding-left: 20px !important;
    margin-top: 20px;
    font-size: 22px;
  }
  .refa_calculul {
    width: fit-content;
    padding-left: 20px !important;
    padding-right: 20px !important;
    font-size: 22px;
  }
  .butoane_cart a {
    width: auto;
  }
}

@media (max-width: 530px) {
  .cap_tabel1 div, .cap_tabel div {
    width: auto;
    min-width: 131px;
  }
  .cap_tabel {
    width: fit-content;
  }
  .tabel_consum{
    overflow-x: auto;
  }
  
  .tabel_consum::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F2F2F2;
  }

  .tabel_consum::-webkit-scrollbar
  {
    height: 6px;
    background-color: #F2F2F2;
  }

  .tabel_consum::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #206FB6;
  }
  
}
