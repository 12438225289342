@font-face {
  font-family: "Teko";
  src: url("../fonts/Teko-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Teko";
  src: url("../fonts/Teko-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Teko";
  src: url("../fonts/Teko-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Teko";
  src: url("../fonts/Teko-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Teko";
  src: url("../fonts/Teko-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Roboto Condensed";
  src: url("../fonts/RobotoCondensed-Regular.ttf");
  font-weight: 400;
}

body,
html {
  height: auto;
}

#header {
  display: block;
  clear: both;
  padding: 40px 0;
  text-align: center;
  line-height: 0;
}

#subheader {
  display: block;
  clear: both;
  text-align: center;
  width: 100%;
  font-family: "Teko", sans-serif;
  /*font-size: 80px;*/
  font-size: 4.17vw;
  color: #000;
  font-weight: 500;
  margin-top: -4px;
}

div#lista-calculatoare {
  display: block;
  clear: both;
  width: 100%;
  /* margin-top: 32px; */
  margin-top: 1.67vw;
}

div#lista-calculatoare-content {
  display: block;
  width: calc(100% - 464px);
  margin: 0 auto;
  /*height: 632px;*/
  height: 32.92vw;
}

div#lista-calculatoare-content .lista-item {
  width: 33.33%;
  float: left;
  background: #e0e0e0;
  position: relative;
  height: 100%;
}

.item-title-grey {
  font-family: "Teko", sans-serif;
  /*font-size: 44px;*/
  font-size: 2.292vw;
  color: #a6a6a6;
  font-weight: 500;
  display: block;
}

.item-main-title {
  font-family: "Teko", sans-serif;
  /*font-size: 60px;*/
  font-size: 3.125vw;
  color: #000;
  font-weight: 500;
  display: block;
  line-height: 0.8;
  /*padding-top: 9px;*/
  padding-top: 0.47vw;
}

.item-imagine img {
  /* width: auto; */
  width: 19vw;
  max-width: 85%;
  height: auto;
}

/* #header img {
  width: 13.6vw;
} */

.item-red-arrow img {
  width: 1.56vw;
}

.item-red-arrow {
  position: absolute;
  right: 30px;
  top: 30px;
}

.lista-item-content {
  display: block;
  /*padding-left: 60px;*/
  padding-left: 3.125vw;
  height: 100%;
}

#root {
  background: #e6e6e6;
}

.lista-item:not(:last-child) .lista-item-content {
  border-right: 1px solid #e6e6e6;
}

.item-imagine-container {
  height: 63%;
  clear: both;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-titles-container {
  height: 37%;
  display: block;
  clear: both;
}

div#lista-calculatoare-content .lista-item:hover {
  -webkit-box-shadow: inset 0px 0px 0px 10px #cdcdcd;
  -moz-box-shadow: inset 0px 0px 0px 10px #cdcdcd;
  box-shadow: inset 0px 0px 0px 10px #cdcdcd;
}

.informatie_constructie_container {
  clear: both;
  display: block;
  width: 100%;
  float: left;
  padding-top: 50px;
}

.informatie_constructie_container > div {
  width: 50%;
  float: left;
}

.informatii_imagine {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-confirmare-oferta {
  height: 100%;
  position: relative;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-confirmare-oferta #subheader {
  font-size: 44px;
  line-height: 54px;
}

.informatii_imagine img {
  max-width: 85%;
}

.input_edit {
  background: url("../img/edit.png") no-repeat center right;
}

.input_no_edit {
  pointer-events: none;
}

.slmon {
  margin-bottom: 30px;
}

.informatii_date_title {
  font-family: "Teko", sans-serif;
  font-size: 44px;
  font-weight: 500;
  display: block;
  width: 100%;
  clear: both;
  padding-top: 88px;
}

input#inputLungimePereti,
input#inputInaltimePerete,
input#inputPret,
input#inputDiscount {
  width: 100%;
  border: none;
  border-bottom: 1px solid #cccccc;
  padding: 0 0 20px;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 20px;
  color: #000;
  outline: none;
}

input#cursMoneda {
  width: 100%;
  border: none;
  border-bottom: 1px solid #cccccc;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 20px;
  color: #000;
  outline: none;
  padding: 15px 0;
}

.input_general {
  display: block;
  padding-top: 23px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.informatii_constructie_needitabile {
  display: block;
  width: 100%;
  float: left;
  margin: 42px 0 41px 0;
}

.informatii_constructie_needitabile > div {
  width: 33%;
  float: left;
}

.informatii_constructie_needitabile > div .needitabil_titlu {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
  color: #666666;
}

.informatii_constructie_needitabile > div .needitabil_valoare {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  color: #000000;
  display: block;
  padding-top: 6px;
}

.pret_discount_content {
  display: flex;
  clear: both;
  align-items: center;
  justify-content: space-between;
}

.pret_discount_content > div {
  width: 47%;
  float: left;
}
