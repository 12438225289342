* {
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.background {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

.flex_12 {
  width: 25%;
  margin-bottom: 20px;
}

.butoane_cart {
  display: flex;
  margin-bottom: 200px;
}

.butoane_cart a {
  width: 34%;
  margin-right: 30px;
  color: #fff;
  font-family: "Teko";
  font-size: 30px;
}

.mtt {
  margin-top: 60px;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  margin: -3px 0 0 -3px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader_full {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.95);
  text-align: center;
  display: none;
}

.bloc_centru {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.se_trimite {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  margin-top: 20px;
}

.cap_tabel_total {
  background: #d9d9d9;
  border: none !important;
}

#componenta-cart {
  width: 990px;
  margin: 0 auto;
  max-width: 100%;
}

.flex_item_produs {
  display: flex;
  align-items: center;
}

.cap_tabel_cart img {
  width: 100px;
}

.nob {
  border: none !important;
}

.adauga_oferta_in_cos {
  font-family: "Teko";
  width: 50%;
  color: #fff;
  font-size: 30px;
  text-align: center;
  padding: 18px 0;
  background: #206FB6;
  margin-top: 90px;
  cursor: pointer;
}

.refa_calculul {
  width: 100%;
  font-size: 30px;
  text-align: center;
  padding: 18px 0;
  background: #b3b3b3;
  margin-top: 90px;
}

.black_item {
  font-size: 30px;
  font-weight: 500;
  font-family: "Teko";
  text-align: left !important;
}

.red_item {
  font-size: 20px;
  font-weight: 600;
  color: #206FB6;
  font-family: "Roboto Condensed";
}

.cap_tabel {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 18px;
  padding-top: 26px;
  padding-left: 30px;
  padding-right: 30px;
}

.denumire_produs_cos {
  text-align: left !important;
  padding-left: 12px;
  padding-right: 0;
}

.cap_tabel div {
  width: 25%;
  text-align: center;
}

.cap_tabel1 div {
  width: 33.33%;
}

.nowrap {
  flex-wrap: wrap;
}

.imagine_necesar img {
  width: 400px;
  max-width: 100%;
}
.input_red {
  background: transparent;
  border: none;
  width: auto;
  color: #206FB6;
  font-size: 20px;
  font-weight: 600;
  font-family: "Roboto Condensed";
}

.sectiune_inputuri {
  width: 1550px;
  padding: 0px 10px;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 55px;
  display: flex;
  justify-content: space-between;
}

.necesar_right {
  width: 62%;
}
.imagine_necesar {
  width: 38%;
}
.tl_mare {
  font-size: 44px;
  font-family: "Teko";
  font-weight: 500;
}

.container_caramida {
  width: 1550px;
  padding: 0px 10px;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 55px;
}

.info_right {
  width: 47%;
}

.container_header2 {
  display: flex;
  justify-content: center;
}
.copy_subheader {
  color: #666666;
  font-size: 16px;
  text-align: center;
  width: 825px;
  margin: 0 auto;
  max-width: 100%;
  font-family: "Roboto Condensed";
  margin-top: 5px;
}

.select_inner {
  display: flex;
  justify-content: space-between;
}

.tl_general {
  font-size: 30px;
  font-family: "Teko";
  font-weight: 500;
  line-height: 30px;
}
.meniu_site {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  z-index: 9;
  background: #e6e6e6 url("../img/shape.png") no-repeat bottom -90px right -90px;
}
.select_general {
  color: #666;
  font-size: 20px;
  font-family: "Roboto Condensed";
  background: transparent;
  border: none;
  border-bottom: 1px solid #cccccc;
  width: 100%;
  padding-bottom: 15px;
  outline: none;
  font-weight: 600;
  margin-top: 20px;
  -webkit-appearance: none;
  background: url("../img/down.png") no-repeat center right;
}

.input_dreapta.input_edit {
  background: url("../img/creion.png") no-repeat top right;
}

.meniu_container {
  padding: 75px 200px;
}

.fadeOut {
  opacity: 0;
  width: 0;
  height: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s, visibility 0.5s;
  visibility: hidden;
}
.fadeIn {
  opacity: 1;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
  visibility: visible;
}

.title_meniu {
  font-size: 46px;
  color: #a6a6a6;
}
.termeni_general {
  font-size: 40px;
  margin-right: 80px;
}
.termeni {
  display: flex;
  margin-left: 200px;
  font-family: "Teko";
  font-weight: 500;
}

.text_center_confirma {
  background: #206FB6;
  margin-top: 30px;
  font-size: 30px;
  color: #fff;
  display: inline-block;
  font-weight: 500;
  font-family: "Teko";
  text-align: center;
  padding: 19px 0px;
  width: 300px;
}

.select_black {
  color: #000 !important;
}

.confirmare-oferta-content {
  text-align: center;
}

.text_meniu {
  margin-left: 50px;
  line-height: 60px;
}
.subtitle_meniu {
  font-size: 70px;
  color: #000;
}
.poza_stanga {
  width: 50%;
}

.info_right {
  width: 50%;
}
.item_meniu {
  display: flex;
  align-items: center;
  font-family: "Teko";
  font-weight: 500;
  margin-bottom: 50px;
}
.poza_stanga img {
  width: 550px;
  max-width: 100%;
}
.select_produse_general {
  width: 1550px;
  padding: 0px 10px;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 55px;
}
.sl_general {
  width: 29%;
}
.input_dreapta {
  color: #000;
  font-size: 20px;
  font-family: "Roboto Condensed";
  background: transparent;
  border: none;
  border-bottom: 1px solid #cccccc;
  width: 100%;
  padding-bottom: 10px;
  outline: none;
  font-weight: 600;
  margin-bottom: 20px;
  -webkit-appearance: none;
  margin-top: 10px;
}

div#calculeaza-button {
  height: 100px;
  background: #206FB6;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0.9;
  cursor: pointer;
  margin: 31px 0 55px 0;
  width: 326px;
  max-width: 100%;
}

.popup_general {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  top: 0;
  left: 0;
}

.popup_general_inner {
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  text-align: center;
  font-size: 20px;
  width: 40%;
}

.btns_block {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}

.btn_prod_gen {
  width: 45%;
  padding: 10px;
  color: #000;
  font-weight: 600;
  cursor: pointer;
}

.nu_sterg_produsul {
  background: #b3b3b3;
}

.sterg_produsul {
  background: #206FB6;
}

.popup_general {
  display: none;
}

.title_calculeaza_nou a {
  color: #666;
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  font-family: "Roboto Condensed";
}
.arrow_cos {
  margin-left: 10px;
}
.text-center {
  text-align: center;
}

.gdpr {
  width: 420px;
  max-width: 100%;
  margin: 0 auto;
}

.chx[type="checkbox"]:not(:checked),
.chx[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
.chx[type="checkbox"]:not(:checked) + label,
.chx[type="checkbox"]:checked + label {
  position: relative;
  font-family: "Roboto Condensed";
  padding-left: 35px;
  cursor: pointer;
  font-size: 15px;
  color: #666666;
  display: inline-block;
  line-height: 25px;
  margin-top: 20px;
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}

.trimite_oferta_pret {
  height: 80px;
  background: #206FB6;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0.9;
  cursor: pointer;
  width: 308px;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 35px;
  margin-bottom: 200px;
  font-weight: 500;
  font-size: 30px;
  color: #fff;
  font-family: "Teko";
}

/* checkbox aspect */
.chx[type="checkbox"] + label:before,
.chx[type="checkbox"] + label:after {
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;

  -webkit-transition: 0.2s;
  transition: 0.2s;
}
/* Unchecked styles */
.chx[type="checkbox"]:not(:checked) + label:before {
  top: 7px;
  width: 19px;
  height: 19px;
  border: 3px solid #000000;
}
.chx[type="checkbox"]:not(:checked) + label:after {
  top: 7px;
  width: 19px;
  height: 19px;
  border: 3px solid #000000;
  z-index: 0;
}
/* Checked styles */
.chx[type="checkbox"]:checked + label:before {
  top: 12px;
  left: 4px;
  width: 4px;
  height: 9px;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 3px solid #000000;
  border-bottom: 3px solid #000000;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 20% 40%;
  transform-origin: 100% 100%;
}
.chx[type="checkbox"]:checked + label:after {
  top: 7px;
  width: 19px;
  height: 19px;
  border: 3px solid #000000;
  z-index: 0;
}
/* disabled checkbox */
.chx[type="checkbox"]:disabled:not(:checked) + label:before,
.chx[type="checkbox"]:disabled:checked + label:before {
  top: 7px;
  box-shadow: none;
  background-color: #444;
  width: 19px;
  height: 19px;
  border: 3px solid #444;
  -webkit-transform: rotateZ(0deg);
  transform: rotateZ(0deg);
}
.chx[type="checkbox"]:disabled + label {
  color: #555;
}
.chx[type="checkbox"]:disabled:not(:checked) + label:hover:before {
  border-color: #000000;
}

.input_email {
  border: none;
  background: transparent;
  text-align: center;
  border-bottom: 6px solid #b3b3b3;
  outline: none;
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  padding-bottom: 10px;
  width: 420px;
  max-width: 100%;
  margin-top: 35px;
  font-family: "Roboto Condensed";
}

.input_email::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000000;
}
.input_email::-moz-placeholder {
  /* Firefox 19+ */
  color: #000000;
}
.input_email:-ms-input-placeholder {
  /* IE 10+ */
  color: #000000;
}
.input_email:-moz-placeholder {
  /* Firefox 18- */
  color: #000000;
}

.item-title {
  font-size: 20px;
  font-family: "Roboto Condensed";
  font-weight: 500;
  color: #666;
}

.calculeaza-label {
  font-weight: 500;
  font-size: 42px;
  display: block;
  color: #fff;
  font-family: "Teko";
}

.calculeaza-label span {
  color: #000000;
  font-weight: 600;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 13.33px;
  display: block;
}

.mortar_select {
  padding-bottom: 10px;
  margin-bottom: 20px;
  margin-top: 0;
}
.inputs_hidden {
  display: none;
}
.show_inputs {
  display: block !important;
}
.sl_general_klinker {
  width: 48%;
}
.sl_general_placaj {
  width: 31%;
}
.flxx {
  display: flex;
}
.spb {
  justify-content: space-between;
}
.input_top {
  margin-bottom: 0;
  padding-top: 15px;
  background: url(../img/creion.png) no-repeat center right !important;
}
.sl_general_caramida {
  width: 100%;
}

.w100 {
  width: 100%;
}

.w32 {
  width: 32%;
}
.w48 {
  width: 48%;
}
.header2 {
  padding: 40px 100px 15px 100px;
}

.container_right {
  display: flex;
  justify-content: space-between;
}
.h100 {
  height: 100%;
  min-height: 100vh;
}

#root {
  height: 100%;
}

.cart {
  position: relative;
}

.circle_products {
  position: absolute;
  right: -10px;
  top: -10px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 50%;
  border: 4px solid black;
  width: 30px;
  height: 30px;
  text-align: center;
}

.container_right img {
  width: 50px;
  height: 50px;
  margin-left: 20px;
}

.sl_j1 {
  margin-bottom: 20px;
}

.sl_j {
  margin-bottom: 20px;
  padding-bottom: 30px;
}

.hidden_element {
  display: none;
}

.item55 {
  width: 55% !important;
  display: flex;
}

.item15 {
  width: 15% !important;
}

.actions_img {
  width: auto !important;
}

.inca_un_produs {
  margin-top: 80px;
  margin-bottom: 65px;
}

.div_input {
  width: 48%;
}

.denumire_produs_cos {
  padding-right: 20px;
  width: calc(100% - 100px) !important;
}
.blank100 {
  width: 100px !important;
  display: inline-block;
  margin-right: 12px;
}

.date_generale .input_dreapta {
  font-weight: 500;
}

.date_generale .select_general {
  margin-top: 5px;
}

.btn_general {
  cursor: pointer;
}

.date_generale {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.around_edit {
  width: 100% !important;
  display: flex;
  justify-content: space-around;
}

.continut_cos_cumparaturi .cap_tabel {
  padding-left: 0;
  padding-right: 0;
}

.continut_cos_cumparaturi .item-title,
.continut_cos_cumparaturi .red_item {
  text-align: left;
}
